import { useEffect, useState, useRef, useMemo } from 'react'

import LoadingContainer from 'components/LoadingContainer'
import ReactMarkdown from 'react-markdown'
import SocialIcon from 'components/SocialIcon'
import StatsBox from './StatsBox.js'
import _ from 'lodash'
import remarkGfm from 'remark-gfm'
import { createGradient, getColorHex, safelyMillify } from 'utils'
import { FaArrowLeft } from 'react-icons/fa'
import { Talent } from 'helpers/Talent'
import { getInfoBoxKeys } from 'helpers/Platform'
import { getSocialName, getDisplayName, getSocialProfileUrl } from 'helpers/Social'
import { AiFillCloseCircle } from 'react-icons/ai'

import useLocalStorage from 'hooks/useLocalStorage'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { sendEvent } from 'analytics'
import { useParams } from 'react-router-dom'
import { joinClassNames } from 'utils'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import config from 'config'
import SignupModal from 'components/SignupModal'
import { FaThumbsUp, FaRegThumbsUp } from 'react-icons/fa'
import { addInfluencerLike, removeInfluencerLike } from 'redux/influencerLikesSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import RenderMarkdown from 'components/RenderMarkdown.js'

const addMarkdownLineBreaks = (text) => {
  return text
    .split('\n')
    .map((line) => line + '  \n')
    .join('')
}

const getBioText = (influencer) => {
  const overViewTextFields = []

  if (!_.isEmpty(influencer.country)) {
    overViewTextFields.push(influencer.country)
  }

  if (!_.isEmpty(influencer.age_range)) {
    overViewTextFields.push(influencer.age_range)
  }
  if (!_.isEmpty(influencer.gender)) {
    overViewTextFields.push(influencer.gender)
  }

  return overViewTextFields.join(' | ')
}

const SocialLink = ({ social, children, style = {}, ...props }) => {
  return (
    <a
      style={{
        textDecoration: 'none',
        color: 'black',
        ...style,
      }}
      href={getSocialProfileUrl(social)}
      target="_blank"
      rel="noreferrer"
      title={`Open ${getSocialName(social.platform)}: ${getDisplayName(social)}`}
      {...props}
    >
      {children}
    </a>
  )
}

const SocialBlock = ({
  socials,
  accentColor = 'shine-gold',
  handleSocialClick,
  selectedSocialId,
  darkMode,
  grayScale,
  longView,
}) => {
  const [showPlatformPopup, setShowPopup] = useLocalStorage('showPopup', true)
  const [showProfilePopup, setShowProfilePopup] = useLocalStorage('showProfilePopup', true)

  const [activatePopups, setActivatePopups] = useState(false)

  const params = useParams()
  const searchParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const clear = setTimeout(() => {
      if (longView) return
      setActivatePopups(true)
    }, 450)

    return () => {
      clearTimeout(clear)
    }
  }, [])

  const backgroundStyle = useMemo(() => {
    if (grayScale) return 'bg-gray-700'
    return { backgroundColor: getColorHex(accentColor) }
  }, [grayScale, accentColor])

  return (
    <div className={joinClassNames(['card border-0 shadow social-box mt-3'])}>
      <div
        className={joinClassNames([
          `card-header text-center`,
          `text-white`,
          `headline`,
          typeof backgroundStyle === 'string' ? backgroundStyle : null,
        ])}
        style={typeof backgroundStyle === 'object' ? backgroundStyle : {}}
      >
        SOCIAL REACH
      </div>
      <div
        className={joinClassNames([
          'social-box-content',
          'd-flex flex-column',
          darkMode ? 'bg-gray-200' : null,
        ])}
      >
        <Tooltip
          anchorSelect="#social-1"
          title={`Switch platform ✖`}
          isOpen={activatePopups && showPlatformPopup}
          place="left"
          clickable={true}
          // delayShow={500}
        >
          <div
            className="selectable"
            onClick={() => {
              setShowPopup(false)
            }}
          >
            Change platform{' '}
            <span style={{ padding: '4px' }}>
              <AiFillCloseCircle />
            </span>
          </div>
        </Tooltip>

        <Tooltip
          anchorSelect="#followers-0"
          isOpen={activatePopups && showProfilePopup}
          place="top"
          // events={['click']}
          clickable={true}
          // delayShow={500}
        >
          <div
            className="selectable"
            onClick={() => {
              setShowProfilePopup(false)
            }}
          >
            Open profile page{' '}
            <span style={{ padding: '4px' }}>
              <AiFillCloseCircle />
            </span>
          </div>
        </Tooltip>

        {_.orderBy(socials, [(obj) => _.isUndefined(obj.followers), _.identity()]).map(
          (social, i) => (
            <div key={`social-${social.id}`} className="my-3">
              <div className="row my-2 ms-2 text-center text-sm-start">
                <div
                  className={`col-4 col-sm-4 text-end align-self-center social-icon-column ${
                    selectedSocialId === social.id ? 'selected-social-icon-column' : ''
                  }`}
                >
                  <SocialIcon
                    id={`social-${i}`}
                    className={joinClassNames([`social-icon selectable`])}
                    style={{
                      backgroundColor: grayScale
                        ? getColorHex('gray-700')
                        : getColorHex(accentColor),
                    }}
                    platform={social.platform}
                    color={'white'}
                    title={`Click for Statistics`}
                    onClick={() => {
                      setShowPopup(false)
                      handleSocialClick(social.id)
                    }}
                  />
                </div>
                <div className="col-6 col-sm-8 d-flex flex-column justify-content-center zoom-hover">
                  {social.followers ? (
                    <>
                      <SocialLink
                        social={social}
                        onClick={() => {
                          setShowProfilePopup(false)
                          sendEvent('social_view', {
                            platform: social.platform,
                            influencer_id:
                              searchParams.get('influencer_id') || params.influencerId,
                            sheet_id: params.talentSheetId,
                            url: getSocialProfileUrl(social),
                          })
                        }}
                      >
                        <div
                          className={joinClassNames([
                            `follower-count text-bold`,
                            darkMode ? 'text-black' : null,
                          ])}
                          id={`followers-${i}`}
                        >
                          <span id={`followers-${i}`}>{safelyMillify(social.followers)}</span>
                        </div>
                      </SocialLink>
                      <div className={'text-black'}>Followers</div>
                    </>
                  ) : (
                    <div className="text-black">
                      <a
                        id={`followers-${i}`}
                        style={{ textDecoration: 'none', color: 'black' }}
                        target="_blank"
                        href={getSocialProfileUrl(social)}
                      >
                        <span id={`followers-${i}`}>{getDisplayName(social)}</span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  )
}

const PostsBox = ({
  posts = [],
  displayPostCategory,
  postCategories,
  setPostCategory,
  accentColor,
  darkMode,
}) => {
  const handleSelectCategory = (value) => {
    setPostCategory(value)
    setSelecting(false)
  }

  const [selecting, setSelecting] = useState(false)

  const forceGrayTextForBlackTheme = accentColor === 'shine-black' ? { color: 'gray' } : {}
  const multipleCategories = postCategories.length > 1

  return (
    <div className="container">
      <div
        className={joinClassNames([
          'shadow posts-box very-round-box',
          !darkMode ? 'bg-white' : null,
          'px-5 py-3',
        ])}
      >
        <div className="posts-box-content position-relative">
          <div className="row justify-content-center py-3">
            <div className="col-12 col-md-10">
              <div className="row justify-content-center">
                {postCategories.map((category, i) => {
                  const textStyle = {}
                  if (multipleCategories && category.value === displayPostCategory.value) {
                    textStyle.color = getColorHex(accentColor)
                  } else if (accentColor === 'shine-black') {
                    textStyle.color = 'gray'
                  }

                  return (
                    <div
                      key={`category-${i}}`}
                      style={textStyle}
                      className={`col-12 col-md-4 mt-3 mt-md-1 headline category-headline horizontal-flanked-text text-center`}
                    >
                      <span
                        className={!multipleCategories ? '' : 'selectable'}
                        onClick={() => {
                          if (!multipleCategories) return
                          setPostCategory(category.value)
                        }}
                      >
                        {category.name}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="row">
            {posts.slice(0, 6).map((post, i) => {
              if (!post.thumbnail) return null
              return (
                <div key={`post-${i}`} className="col-12 col-sm-6 col-md-4">
                  <div className="post-card mb-3">
                    <a href={post.link} target="_blank">
                      <div
                        className="image-holder"
                        style={{ backgroundImage: `url(${post.thumbnail})` }}
                      ></div>
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const postCategories = [
  { name: 'Top Posts', value: 'top_posts' },
  { name: 'Top Reels', value: 'top_reels' },
  { name: 'Recent Posts', value: 'recent_posts' },
  { name: 'Sponsorships', value: 'commercial_posts' },
]

export default function PublicProfilev2({
  influencer,
  socials,
  influencerNote = null,
  openAccess = true,
  showBackArrow = false,
  showEmail = false,
  onBack = () => {},
  settings = { excluded_post_ids: [] },
  displaySettings = {},
  talentSheetId,
  longView = false,
}) {
  const talent = new Talent(influencer, socials)
  const talentSocials = talent.getSocials()
  const dispatch = useDispatch()

  const [displaySignupModal, setDisplaySignupModal] = useState(false)

  const [influencerLikes, setInfluencerLikes] = useLocalStorage('influencerLikes', {
    sheets: {},
    rosters: {},
  })

  const currentInfluencerIsLiked =
    influencerLikes.sheets[talentSheetId] &&
    influencerLikes.sheets[talentSheetId].includes(influencer.id)

  const talentSheet = useSelector((state) => state.allSheets.entities[talentSheetId])
  const currentUser = useSelector((state) => state.user)
  const influencerHasLike = useSelector((state) => {
    const foundLike = state.influencerLikes.activeSheetLikes.find((like) => {
      return like.influencer_id === influencer.id
    })
    return !!foundLike
  })

  const registerLike = () => {
    const likeData = {
      influencer_id: influencer.id,
      salesheet_id: talentSheetId,
      team_id: talentSheet?.team_id,
    }

    if (influencerHasLike) {
      dispatch(removeInfluencerLike(likeData))
      return
    }

    dispatch(addInfluencerLike(likeData))
  }

  const influencerSocials = talentSocials.allSocials
  const primarySocial = talentSocials.primaryAccount

  const [selectedSocialId, setSelectedSocialId] = useState(null)

  const darkMode = displaySettings.displayDarkMode
  const grayScale = displaySettings.displayGrayScale

  const accentColor = displaySettings?.displayAccentColor || 'shine-gold'

  const imageShouldInvert = darkMode && !displaySettings.displayDarkLogo

  const [selectedPostCategory, setPostCategory] = useState(null)

  const selectedSocial = socials[selectedSocialId] || primarySocial

  const backgroundStyle = useMemo(() => {
    if (displaySettings.displayProfileBackground)
      return { backgroundColor: getColorHex(displaySettings.displayProfileBackground) }

    if (accentColor.startsWith('gray-')) {
      return { backgroundColor: 'white' }
    }

    return {
      background: createGradient(accentColor),
    }
  }, [displaySettings, accentColor])

  const descriptionText = useMemo(() => {
    return influencer.custom_description || influencer.description || ''
  }, [influencer])

  const availablePostTypes = postCategories.filter((p) => {
    return !_.isEmpty(_.get(selectedSocial, p.value))
  })

  let displayPostCategory = useMemo(() => {
    if (availablePostTypes.length > 0) {
      return selectedPostCategory
        ? _.find(postCategories, { value: selectedPostCategory })
        : availablePostTypes[0]
    }
  }, [availablePostTypes, selectedPostCategory])

  let displayPostsArray = useMemo(() => {
    if (!selectedSocial) return []

    let displayPostsResult = []

    if (availablePostTypes.length > 0) {
      displayPostsResult = _.get(selectedSocial, displayPostCategory.value)
    }

    const excluded_post_ids = settings.excluded_post_ids
    if (excluded_post_ids.length > 0) {
      displayPostsResult = displayPostsResult.filter((p) => !excluded_post_ids.includes(p.post_id))
    }

    return displayPostsResult
  }, [selectedSocial, displayPostCategory, settings.excluded_post_ids])

  if (_.isEmpty(primarySocial)) {
    return <LoadingContainer />
  }

  const portrait_url = talent.getPhotoUrl()

  const overViewText = getBioText(influencer)

  let infoBoxes = getInfoBoxKeys(selectedSocial.platform)
  const disabledInfoBoxes = _.get(influencer, 'team_settings.disabledInfoBoxes', [])

  const advancedDataAvailable = (selectedSocial, keys) => {
    if (_.isEmpty(selectedSocial)) return false
    console.log('selectedSocial', selectedSocial)
    console.log(keys)
    const available = keys
      .map((k) => {
        return selectedSocial[k]
      })
      .filter((r) => r)
    console.log('available key', available)
    return available.length > 0
  }

  const displayName = influencer.team_settings?.custom_display_name
    ? influencer.team_settings.custom_display_name
    : influencer.fullname

  return (
    <div
      className={joinClassNames([
        'container-fluid profile-v2 position-relative',
        darkMode ? 'dark-mode' : null,
      ])}
      style={backgroundStyle}
    >
      <Helmet>
        <title>{displayName} Profile</title>
      </Helmet>
      {showBackArrow && (
        <div className="selectable">
          <span
            style={{ fontSize: '1.8em', position: 'absolute', top: '10px', left: '50px' }}
            data-purpose="back-arrow"
            onClick={onBack}
          >
            <FaArrowLeft />
          </span>
        </div>
      )}{' '}
      <div className="container-xl">
        <div className="row justify-content-center justify-content-sm-end">
          <div className="col-sm-12 col-md-8 col-lg-9">
            <div className="row">
              <div className="col-4">
                <div className="profile-team-logo-container d-flex">
                  {settings.teamLogo && (
                    <img
                      className={joinClassNames([
                        `profile-team-logo`,
                        imageShouldInvert ? 'dark-mode-image' : null,
                      ])}
                      src={settings.teamLogo}
                    />
                  )}
                </div>
              </div>
              {!_.isEmpty(influencerNote) && (
                <div
                  style={{ fontSize: '1.1em' }}
                  className="col-6 ms-2 text-center text-bold align-self-center"
                >
                  <RenderMarkdown markdownText={influencerNote} />
                </div>
              )}
            </div>
            <hr />
            <div className="row px-3">
              <div className="col-12 col-sm-4 mb-3 mb-sm-0 text-center align-self-center">
                <div className="profile-picture-container portrait-rounded">
                  <img src={portrait_url} />
                  <div className="text-center my-3">
                    {/* <button
                      className={joinClassNames([
                        `btn btn-primary btn-sm`,
                        'bg-white',
                        'py-1 px-3',
                      ])}
                      onClick={() => {
                        if (currentUser.authed) {
                          registerLike()
                        } else {
                          setDisplaySignupModal(true)
                        }
                      }}
                    >
                      {influencerHasLike ? (
                        <FaThumbsUp color={config.colors[accentColor]} />
                      ) : (
                        <FaRegThumbsUp color={config.colors[accentColor]} />
                      )}
                    </button> */}
                  </div>
                </div>
                {influencer.tagline && (
                  <div
                    style={{ fontSize: '1em', minHeight: '1.5em' }}
                    className="text-center fw-bold mt-3"
                  >
                    {influencer.tagline}
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-8">
                <div className="d-flex flex-column">
                  <div className="talent-name uppercase headline text-center mb-2">
                    {displayName}
                  </div>
                  {settings.showInfluencerBio ? (
                    <div className="text-center mb-2">{overViewText}</div>
                  ) : (
                    <div className="mb-2"></div>
                  )}
                  <div className="talent-description-text">
                    <RenderMarkdown markdownText={descriptionText} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-4 col-lg-3 align-content-center social-block-column">
            <SocialBlock
              selectedSocialId={selectedSocialId || primarySocial.id}
              socials={influencerSocials}
              accentColor={accentColor}
              handleSocialClick={(socialId) => {
                setSelectedSocialId(socialId)
                setPostCategory(null)
              }}
              darkMode={darkMode}
              grayScale={grayScale}
              longView={longView}
            />
          </div>
        </div>
        <div className="container">
          {advancedDataAvailable(selectedSocial, infoBoxes) ? (
            <div className="row my-4">
              {infoBoxes.map((k) => {
                if (disabledInfoBoxes.includes(k) || displaySettings.displayNoWidgets) return null
                return (
                  <div key={k} className="col-12 col-sm-6 col-lg-4 my-3">
                    <StatsBox
                      infoKey={k}
                      audience_followers={{
                        ...selectedSocial.audience_followers,
                      }}
                      advanced_data={{
                        engagement_rate: selectedSocial.engagement_rate,
                        avg_likes: selectedSocial.avg_likes,
                        avg_views: selectedSocial.avg_views,
                      }}
                      darkMode={darkMode}
                      grayScale={grayScale}
                      displaySettings={displaySettings}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="row my-4">
              <div
                className={joinClassNames([
                  'very-round-box',
                  !darkMode ? 'bg-white' : null,
                  'p-3',
                ])}
              >
                {influencer.with_advanced_data ? (
                  <h4 className="text-center">
                    Detailed audience data not available.{' '}
                    <SocialLink style={{ textDecoration: 'underline' }} social={selectedSocial}>
                      Visit Profile
                    </SocialLink>
                  </h4>
                ) : (
                  <LoadingContainer />
                )}
              </div>
            </div>
          )}
        </div>
        {displayPostsArray.length > 0 && (
          <div className="row my-4 d-print-none">
            <div className="col-12">
              <PostsBox
                posts={displayPostsArray}
                postCategories={availablePostTypes}
                displayPostCategory={displayPostCategory}
                setPostCategory={setPostCategory}
                accentColor={accentColor}
                darkMode={darkMode}
              />
            </div>
          </div>
        )}
      </div>
      <SignupModal
        showModal={displaySignupModal}
        handleClose={() => setDisplaySignupModal(false)}
      />
    </div>
  )
}
