import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import InfluencerFilters from './components/InfluencerFilters'
import PageMenu from './PageMenu'
import TalentRosterTable from './TalentRosterTable'
import applyFilters from './applyFilters'
import { useSelector } from 'react-redux'
import ClickToCopy from 'components/ClickToCopy'
import { selectTeamSettings } from './redux/teamSlice'
import { Helmet } from 'react-helmet'
import useLocalStorage from 'hooks/useLocalStorage'
import SocialIcon from './components/SocialIcon'
import { safelyMillify } from 'utils'

const orderInfluencers = (influencers, orderBy) => {
  if (!orderBy) return influencers

  if (orderBy === 'fullname') {
    return _.orderBy(influencers, 'fullname')
  }

  if (orderBy === 'last_name') {
    // try to split the full name and sort by last name. it won't always work
    return _.orderBy(influencers, (influencer) => {
      const splitName = influencer.fullname.split(' ')
      return splitName[splitName.length - 1]
    })
  }

  // fallback, return the original list if we don't know how to order
  return influencers
}

const rotateOrderBy = (orderBy) => {
  const rotation = [null, 'fullname', 'last_name']
  const currentIndex = rotation.indexOf(orderBy)
  return rotation[(currentIndex + 1) % rotation.length]
}

export default function TeamTalentPrivateRoster() {
  const pageLimit = 50
  const navigate = useNavigate()

  const team_influencer_ids = useSelector((state) => state.influencers.team_influencer_ids)

  const influencers = useSelector((state) => {
    return state.influencers.entities
  })

  const teamInfluencers = useMemo(
    () => _.pick(influencers, team_influencer_ids),
    [influencers, team_influencer_ids],
  )

  const socials = useSelector((state) => state.socials.entities)

  const total_influencers = useSelector((state) => state.influencers.total_influencers)
  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const [orderBy, setOrderBy] = useLocalStorage('rosterOrderBy', null)
  const tagged_influencers = useSelector((state) => state.tags.tagged_influencers)
  const [filteringTags, setFilteringTags] = useState([])
  const [nameFilter, setNameFilter] = useState('')

  const displayedInfluencers = useMemo(() => {
    if (_.isEmpty(influencers)) return []
    let influencersToShow = _.map(teamInfluencers, (influencer) => influencer)

    if (nameFilter.trim() !== '' || filteringTags.length > 0) {
      influencersToShow = applyFilters({
        influencers: influencersToShow,
        filteringTags,
        nameFilter,
        taggedInfluencers: tagged_influencers,
        orderBy,
      })
    } else {
      if (orderBy) {
        console.log('order by', orderBy)
        influencersToShow = orderInfluencers(influencersToShow, orderBy)
      }
      influencersToShow = influencersToShow.slice((page - 1) * pageLimit, page * pageLimit)
    }
    return influencersToShow
    // setDisplayedInfluencers(influencersToShow)
  }, [filteringTags, page, orderBy, teamInfluencers, nameFilter])

  const reach = useSelector((state) => state.influencers.reach)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  const totalPages = Math.ceil(total_influencers / pageLimit)

  const showPageMenus = totalPages > 1 && _.isEmpty(filteringTags) && _.isEmpty(nameFilter)

  let handleChangeName = (value) => {
    setNameFilter(value)
  }

  handleChangeName = _.debounce(handleChangeName, 600)

  const [shortDescriptions, setShortDescriptions] = useLocalStorage(
    'rosterShortDescriptions',
    false,
  )

  if (_.isEmpty(influencers) || _.isEmpty(socials)) {
    return <div>Loading...</div>
  }

  return (
    <div className="m-0 p-0 bg-white">
      <Helmet>
        <title>Talent Pitch Pro - Talent Roster</title>
      </Helmet>
      <div className="container pt-3">
        {!_.get(user, 'teamSettings.noTalentSheets') && (
          <div className="mb-3" style={{ fontSize: '1.3em' }}>
            <Link
              data-purpose="public-roster-link"
              to={`/public/talent-roster/${user.selectedTeamId}`}
            >
              Public Link for Sharing Roster
            </Link>
            <ClickToCopy
              iconId={'share-profile-copy-icon'}
              textToCopy={`${window.location.origin}/public/talent-roster/${user.selectedTeamId}`}
            />
          </div>
        )}
        {!_.isEmpty(reach) && (
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center" style={{ fontSize: '1.2em' }}>
                    Total Roster Audience
                  </h5>
                  <div
                    style={{
                      fontSize: '1.1em',
                    }}
                    className="d-flex flex-row justify-content-around"
                  >
                    {Object.entries(reach).map(([network, total]) => {
                      if (!total) return null
                      return (
                        <div key={network}>
                          <SocialIcon platform={network} /> {safelyMillify(total)}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <InfluencerFilters
            filteringTags={filteringTags}
            setFilteringTags={setFilteringTags}
            setNameFilter={setNameFilter}
            allowHide={true}
          />
        </div>

        <div className="row my-3" style={{ fontSize: '1.1em' }}>
          <div className="col-12 col-md-6">
            <div className="p-3">
              <Link to="/tagging-assistant">Tagging Assistant </Link>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="p-3">
              <Link to="/influencers/tags">View All Tags</Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col ms-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="shortDescriptions"
              checked={shortDescriptions}
              onChange={() => setShortDescriptions(!shortDescriptions)}
            />
            <label className="form-check-label" htmlFor="shortDescriptions">
              Short Descriptions
            </label>
          </div>
        </div>
      </div>
      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/influencers" />
      )}

      <TalentRosterTable
        displayedInfluencers={displayedInfluencers}
        socials={socials}
        orderBy={orderBy}
        setOrderBy={() => {
          const nextRotation = rotateOrderBy(orderBy)
          setOrderBy(nextRotation)
        }}
        settings={teamSettings}
        shortDescriptions={shortDescriptions}
      />
      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/influencers" />
      )}
    </div>
  )
}
