import normalMillify from 'millify'
import Color from 'color'
import config from './config'

export const shortenText = (text, maxLength = 100) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '…'
  } else {
    return text
  }
}

export const safelyMillify = (num) => {
  if (!num) {
    return ''
  }
  return normalMillify(num)
}

export const addMarkdownLineBreaks = (text) => {
  if (!text) {
    return ''
  }
  return text
    .split('\n')
    .map((line) => line + '  \n')
    .join('')
}

/**
 * Joins an array of class names or any number of class name strings into a single string,
 *    with each class name separated by a space.
 *
 * @param {...(string|null|undefined|string[])} classNames - Either an array of class names or
 *    any number of class name strings, which can include null or undefined values.
 * @returns {string} - A string containing all the class names joined together with a space delimiter.
 */ export const joinClassNames = (...classNames) => {
  if (classNames.length === 1 && Array.isArray(classNames[0])) {
    classNames = classNames[0] // handle the case where a single array argument is passed
  }
  return classNames.filter((className) => !!className).join(' ')
}

function parseRgbString(rgbString) {
  const rgbValues = rgbString.match(/\d+/g)
  return rgbValues.map(Number)
}

export function getContrastColor(rgb) {
  const [r, g, b] = parseRgbString(rgb)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000
  return brightness > 128 ? 'black' : 'white'
}

export function isValidHex(color) {
  return /^#([0-9A-F]{3}){1,2}$/i.test(color)
}

export function getColorHex(colorName = 'white') {
  if (isValidHex(colorName)) {
    return colorName
  }
  try {
    let colorValue = config.colors[colorName] || colorName
    if (!colorValue.startsWith('#')) {
      // If the value isn't already a hex color, convert it using the Color library
      colorValue = Color(colorValue).hex()
    }
    return colorValue
  } catch (e) {
    console.error('Error converting color to HEX:', e)
    // Return a default color or handle the error as appropriate
    return '#000000'
  }
}

export function getContrastingTextForBackground(backgroundColor = 'white') {
  try {
    // Use the provided backgroundColor if it's a valid hex color,
    // otherwise fall back to the color defined in the config
    if (!isValidHex(backgroundColor)) {
      backgroundColor = config.colors[backgroundColor] || backgroundColor
    }
    const color = Color(backgroundColor)
    const luminosity = color.luminosity() // get the luminance value of the color
    // A threshold luminosity value determines whether to use black or white text.
    // This threshold may need to be adjusted for your specific needs.
    return luminosity > 0.5 ? 'black' : 'white'
  } catch (e) {
    console.error('Error getting contrasting text color for background color:', e)
    return 'black'
  }
}

export function scaleColor(colorValue, lightness = 92) {
  try {
    let color = Color(colorValue)
    // Calculate the new lightness value based on the provided lightness percentage
    let newLightness = lightness

    // Set the new lightness value
    color = color.lightness(newLightness)

    return color.hex()
  } catch (e) {
    console.error('Error scaling color:', e)
    return colorValue // return the original color if an error occurs
  }
}

export function mixWithWhite(colorHex, whiteIntensity) {
  try {
    const color = Color(colorHex)
    const mixedColor = color.mix(Color('#ffffff'), whiteIntensity)
    return mixedColor.hex()
  } catch (e) {
    console.error('Invalid color input:', e.message)
    return null
  }
}

export function mixWithBlack(colorHex, blackIntensity) {
  try {
    const color = Color(colorHex)
    const mixedColor = color.mix(Color('#000000'), blackIntensity)
    return mixedColor.hex()
  } catch (e) {
    console.error('Invalid color input:', e.message)
    return null
  }
}

function createGradientStops(colorHex) {
  return [
    mixWithWhite(colorHex, 0.5), // 50% towards white
    mixWithWhite(colorHex, 0.8), // 80% towards white
    mixWithWhite(colorHex, 0.95), // 95% towards white
    mixWithWhite(colorHex, 0.99), // 99% towards white
  ]
}

export function createGradient(colorValue) {
  try {
    const hexColor = getColorHex(colorValue)
    const stops = createGradientStops(hexColor)
    return `linear-gradient(
    0deg,
    ${stops[0]} 0%,
    ${stops[1]} 10%,
    ${stops[2]} 20%,
    ${stops[3]} 100%
  )`
  } catch (e) {
    console.error('Error creating gradient:', e)
    return ''
  }
}

export function extractRateInfo(text) {
  const regex = /Rates?: \$([\d,]+) USD/i
  const match = regex.exec(text)
  if (match) {
    return '$' + match[1]
  }
  return null
}

export function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(dateString).toLocaleDateString('en-US', options)
}

export const getSocialName = (platform) => {
  if (platform === 'youtube') return 'YouTube'
  if (platform === 'tiktok') return 'TikTok'
  if (platform === 'instagram') return 'Instagram'
  return platform
}
