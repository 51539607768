import React, { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import RenderMarkdown from './RenderMarkdown'
import { Link } from 'react-router-dom'
import TeamHeader from './TeamHeader'
import _, { isEmpty } from 'lodash'
import 'styles/_Switch.scss'
import useLocalStorage from 'hooks/useLocalStorage'
import { hideCall, showCall, setUserHiddenCalls } from 'redux/castingCallsSlice'
import { useDispatch, useSelector } from 'react-redux'

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(dateString).toLocaleDateString('en-US', options)
}

const CastingCallDisplay = ({
  coverImage,
  title,
  description,
  start_date,
  end_date,
  deliverables,
  locations,
  compensation,
  brandRepresented,
  displayImages,
  responseUrl,
  team_image_url,
  team_name,
  response_sheet_id,
  response_status,
  id,
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)

  const [confirmationHide, setConfirmationHide] = useState(null)

  const dispatch = useDispatch()

  const userHiddenCalls = useSelector((state) => state.castingCalls.userHiddenCalls)

  const openLightbox = (index) => {
    setActiveImageIndex(index)
    setLightboxOpen(true)
  }

  const isHidden = userHiddenCalls.includes(id)

  const handleToggle = (id) => {
    let result

    if (isHidden) {
      result = userHiddenCalls.filter((callId) => callId !== id)
    } else {
      setConfirmationHide(true)
      result = [...userHiddenCalls, id]
    }

    if (isHidden) {
      dispatch(showCall(id))
    } else {
      setTimeout(() => {
        setConfirmationHide(false)
        dispatch(setUserHiddenCalls(result))
      }, 3000)
    }
  }

  if (confirmationHide) {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="alert alert-success" role="alert">
            This call will not be shown again
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card mb-3">
      {response_sheet_id && (
        <div className="card-header">
          <div className="text-end">
            Your team has{' '}
            {response_status === 'submitted' ? (
              <>
                sent <Link to={responseUrl}>a response</Link>.
              </>
            ) : (
              <>
                created a <Link to={responseUrl}>draft</Link>.
              </>
            )}
          </div>
        </div>
      )}
      <div className="row d-flex justify-content-center">
        <TeamHeader team={{ image_url: team_image_url, team_name }} />
      </div>

      <div className="card-body">
        <Link to={responseUrl}>
          <h2 className="card-title text-center">{title}</h2>
        </Link>
        <div className="d-flex justify-content-between pt-2">
          <p className="card-text">
            <small className="text-muted">Start Date: {formatDate(start_date)}</small>
          </p>
          <p className="card-text">
            <small className="text-muted">End Date: {formatDate(end_date)}</small>
          </p>
        </div>

        <div style={{ fontSize: '1.1em' }}>
          <RenderMarkdown markdownText={description} />
        </div>

        {!_.isEmpty(brandRepresented) && (
          <>
            <hr />
            <p className="card-text">
              <strong>Brand Represented:</strong> {brandRepresented}
            </p>
          </>
        )}

        {!isEmpty(deliverables) && (
          <>
            <hr />
            <p className="card-text">
              <strong>Deliverables:</strong> {deliverables}
            </p>
          </>
        )}

        {!isEmpty(locations) && (
          <>
            <hr />
            <p className="card-text">
              <strong>Location(s):</strong> {locations}
            </p>
          </>
        )}

        {!isEmpty(compensation) && (
          <>
            <hr />
            <p className="card-text">
              <strong>Compensation:</strong> {compensation}
            </p>
          </>
        )}

        {displayImages && (
          <div className="campaign-images">
            {displayImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Campaign Image ${index + 1}`}
                onClick={() => openLightbox(index)}
                style={{ cursor: 'pointer', maxWidth: '100px', margin: '5px' }} // Thumbnail style
              />
            ))}
          </div>
        )}

        {lightboxOpen && (
          <Lightbox
            open={lightboxOpen}
            close={() => setLightboxOpen(false)}
            slides={displayImages.map((image) => ({ src: image }))}
            index={activeImageIndex}
          />
        )}
        <div className="text-end">
          <button className="btn btn-link" onClick={() => handleToggle(id)}>
            {isHidden ? 'Restore this call' : "Don't show this call"}
          </button>
          {/* <label className="switch">
            <input
              type="checkbox"
              checked={!userHiddenCalls.includes(id)}
              onChange={() => handleToggle(id)}
            />
            <span className="slider round"></span>
          </label> */}
        </div>
      </div>
    </div>
  )
}

export default CastingCallDisplay
