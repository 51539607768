import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCastingCalls } from 'redux/castingCallsSlice'
import CastingCallDisplay from 'components/CastingCallDisplay'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate } from 'utils'
import useLocalStorage from 'hooks/useLocalStorage'

function shortenText(text, maxChars = 140) {
  if (text.length <= maxChars) {
    return text
  }
  return text.slice(0, maxChars) + '...'
}

const RenderCalls = ({ calls }) => {
  return calls.map((castingCall, i) => (
    <div key={`casting-call-${castingCall.id}`} className="row">
      <div
        key={`cc-${castingCall.id}`}
        className="col-12 col-md-8 p-2 mx-auto my-4 shadow-sm bg-white"
      >
        <CastingCallDisplay
          responseUrl={`/creator-call-response/${castingCall.id}`}
          {...castingCall}
        />
      </div>
    </div>
  ))
}

const CreatorCalls = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showHiddenCalls, setShowHiddenCalls] = useState(false)
  const userHiddenCalls = useSelector((state) => state.castingCalls.userHiddenCalls)

  const [showResponded, setShowResponded] = useState(false)

  const castingCalls = useSelector((state) => {
    return state.castingCalls.castingCalls.filter((call) => {
      return !userHiddenCalls.includes(call.id)
    })
  })

  useEffect(() => {
    dispatch(fetchCastingCalls())
  }, [])

  const unRespondedCalls = castingCalls.filter((castingCall) => {
    return !castingCall.response_sheet_id || castingCall.response_status === 'created'
  })

  const respondedCalls = castingCalls.filter((castingCall) => {
    return castingCall.response_sheet_id && castingCall.response_status === 'submitted'
  })

  const hiddenCalls = useSelector((state) => {
    return state.castingCalls.castingCalls.filter((call) => {
      return userHiddenCalls.includes(call.id)
    })
  })

  return (
    <div className="container-fluid bg-sandy">
      <div className="container bg-gray-100 py-3">
        <h1 className="text-center py-3">Available Creator Calls</h1>

        {castingCalls.length === 0 && (
          <div className="text-center">
            <p>There are no available calls at this time. Check back later!</p>
          </div>
        )}

        {unRespondedCalls.length > 0 && <RenderCalls calls={unRespondedCalls} />}
        <hr />
        <div className="row mb-3">
          <div className="col-12 col-md-6 mx-auto">
            <div className="form-check">
              <input
                id="showResponded"
                className="form-check-input"
                type="checkbox"
                value={showResponded}
                onChange={(e) => setShowResponded(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="showResponded">
                Show Calls My Team Has Responded To
              </label>
            </div>
          </div>
        </div>
        {userHiddenCalls.length > 0 && (
          <div className="row mb-3">
            <div className="col-12 col-md-6 mx-auto">
              <div className="form-check">
                <input
                  id="showHidden"
                  className="form-check-input"
                  type="checkbox"
                  value={showHiddenCalls}
                  onChange={(e) => setShowHiddenCalls(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="showHidden">
                  Show Calls I have previously hidden
                </label>
              </div>
            </div>
          </div>
        )}

        {showResponded && <RenderCalls calls={respondedCalls} />}
        {showHiddenCalls && <RenderCalls calls={hiddenCalls} />}
      </div>
    </div>
  )
}

export default CreatorCalls
